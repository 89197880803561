function burgerMenu() {
    const body = document.body
    const header = document.querySelector('.header')
    const burger = document.querySelector('.header__burger')
    const links = document.querySelectorAll('a.menu__link')
    const headerMenuItems = document.querySelectorAll('.menu__item')

    function hideMenu() {
      header.classList.remove('--active')
      body.classList.remove('--lock')
    }

    burger.addEventListener('click', () => {
      headerMenuItems.forEach((otherItem) => {
        if (otherItem.classList.contains('--show')) {
          otherItem.classList.remove('--show')
        }
      })
      header.classList.toggle('--active')
      body.classList.toggle('--lock')
    })

    links.forEach((link) => link.addEventListener('click', () => header.classList.contains('--active') && hideMenu()))

    function isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return true;
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
      }
      return false;
    }

    headerMenuItems.forEach((item) => {
      if (isMobile() || window.innerWidth <= 1200) {
        item.addEventListener('click', () => {
          item.classList.toggle('--show')
        })
      } else {
        item.addEventListener('mouseover', () => {
          headerMenuItems.forEach((otherItem) => otherItem.classList.remove('--show'))
          item.classList.add('--show')
        })
        item.addEventListener('mouseout', () => {
          item.classList.remove('--show')
        })
      }
    })

    const headerSearchBtn = document.querySelector('.header-search__btn')

    headerSearchBtn.addEventListener('click', () => {
      headerSearchBtn.parentNode.classList.toggle('--active');
      document.body.classList.toggle('overflow-hidden')
    })
}

burgerMenu()

function preload() {
  document.addEventListener("DOMContentLoaded", () => {
      const preloader = document.querySelector('.preload-bg');
      preloader.style.opacity = '0';
      preloader.style.zIndex = '-1';
  })
}

preload()


document.addEventListener('DOMContentLoaded', function () {
  const popupBg = document.querySelector('.info-popup-bg');
  const popups = document.querySelectorAll('.info-popup');
  const links = document.querySelectorAll('.policy-link span');
  const closeButtons = document.querySelectorAll('.info-popup .close');

  function openPopup(popupIndex) {
      popupBg.classList.add('active');
      popups[popupIndex].classList.add('active');
  }

  function closePopups() {
      popupBg.classList.remove('active');
      popups.forEach(popup => popup.classList.remove('active'));
  }

  links.forEach((link, index) => {
      link.addEventListener('click', function () {
          closePopups();
          openPopup(index);
      });
  });

  popupBg.addEventListener('click', closePopups);

  closeButtons.forEach(closeButton => {
      closeButton.addEventListener('click', closePopups);
  });
});
